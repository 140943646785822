<template>
  <div class="lesiurpackages mt-3">
    <div class="container">
      <a :href="bannerHref" v-if="showBanner">
        <div
          class="lesiurpackages_head"
          :style="
            bannerImage
              ? `background-image: url(${bannerImage})`
              : ''
          "
        >
          <!-- <h1>{{ $t("home.slide-deal.leisure-packages") }}</h1> -->
        </div>
      </a>
      <b-tabs>
        <b-tab v-for="(dealListItem, index) in dealList" :key="index" :active="index === activeIndex"
          @click="ev => changeTab(index)">
          <template #title>
            <div :class="{ 'tab-title': dealList.length > 4 }">
              {{ dealListItem.name }}
            </div>
          </template>
          <b-card-text>
            <LesiurtabContent :dealListItem="dealListItem" :index="index" :active="index === activeIndex"
              :activeIndex="activeIndex" :filterConditions="getFilterConditions" />
          </b-card-text>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab, BCardText } from 'bootstrap-vue';
import TemplateLesiurtabs from './TemplateLesiurtabs';

export default {
  name: 'LesiurtabsTheme2',
  mixins: [TemplateLesiurtabs],
  components: {
    LesiurtabContent: () => import('@/components/home/lesiurtabContent/LesiurtabContentTheme2'),
    BTabs,
    BTab,
    BCardText,
  },
};
</script>
<style>
.lesiurpackages ul.nav.nav-tabs {
  border: none;
  margin-top: 15px;
  margin-bottom: 15px;
  direction: rtl;
}

.lesiurpackages ul.nav.nav-tabs li {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #525252;
  padding: 6px 6px;
  border: none;
  position: relative;
  min-width: 31%;
  max-width: 31%;
  margin-bottom: 8px;
  background-color: rgb(0 0 0 / 3%);
  border-radius: 6px;
  margin: 5px 1%;
}

.lesiurpackages ul.nav.nav-tabs li a {
  width: 100%;
  height: 100%;
  border: none;
  padding: 4px;
  margin: 0 0;
  background: transparent;
  color: #525252;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
}

.lesiurpackages ul.nav.nav-tabs li a div {
  word-wrap: break-word;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.lesiurpackages ul.nav.nav-tabs li a.active {
  height: 100%;
  color: #0061AB;
  background-color: transparent;
  border-color: transparent;
}

.lesiurpackages ul.nav.nav-tabs li:has(>.active)::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #1993C8;
  box-shadow: 0px 3px 6px #1993C8;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -1px;
}

.lesiurpackages .tab-content {
  border: none;
}

@media (max-width: 479px) {
  .lesiurpackages_head h1 {
    font-size: 18px;
  }

  .lesiurpackages_head {
    min-height: auto;
    padding: 20px 20px;
  }

  .lesiurpackages ul.nav.nav-tabs li a {
    font-size: 14px;
  }

  .lesiurpackages ul.nav.nav-tabs {
    padding-right: 0px;
  }

  .lesiurpackages_hotelsbox.big {
    height: 300px;
  }
}
</style>
